
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  components: {
    TmAlert,
    TmModal,
    TmButton,
    TmFormLine,
  },
  setup() {
    const val = ref('mycompany')

    return {
      val,
    }
  },
})
